import iOS from "is-ios";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useWindowSize } from "react-use/esm/index.js";

export const RemSizingContext = createContext({
  rootSize: 16,
  setRootSize: (e) => {},
  windowWidth: 0,
  windowHeight: 0,
});
export const RemSizingProvider = ({
  defaultValue = 16,
  children,
}: {
  children?: ReactNode;
  defaultValue?: number;
}) => {
  const [rootSize, setRootSize] = useState(defaultValue);
  const { width, height } = useWindowSize();

  useEffect(() => {
    const html = document.querySelector("html");
    const doc = document.documentElement;

    if (html) {
      html.style.fontSize = `${rootSize}px`;
    }
    doc.style.setProperty("--font-size-pixels", `${rootSize}`);
  }, [rootSize]);

  useEffect(() => {
    if (height < 900 && !iOS) {
      setRootSize(14);
    } else {
      setRootSize(16);
    }
  }, [width, height, setRootSize]);
  return (
    <RemSizingContext.Provider
      value={{ rootSize, setRootSize, windowWidth: width, windowHeight: height }}
    >
      {children}
    </RemSizingContext.Provider>
  );
};
