import { cn } from "@/lib/utils.ts";
import { DumbModalSystemRef } from "@/singletons/machines.ts";
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	isRouteErrorResponse,
	useRouteError,
} from "@remix-run/react";
import { withSentry } from "@sentry/remix";
import { useSelector } from "@xstate/react";
import { zipDealVersionMiddleware } from "app/middleware/zipDealVersion.server.ts";
import { enableMapSet, setAutoFreeze } from "immer";
import React, { type ReactNode } from "react";
import "react-day-picker/dist/style.css";
import { serverOnly$ } from "vite-env-only/macros";
import { apolloClientMiddleware } from "~/middleware/apolloClient.server.ts";
import { authenticatedUserMiddleware } from "~/middleware/authenticatedUserMiddleware.server.ts";
import { dealershipMiddleware } from "~/middleware/dealershipMiddleware.server.ts";
import { requestInfoMiddleware } from "~/middleware/requestInfoMiddleware.server.ts";
import { ProvideAuth } from "~common/hooks/useAuth.tsx";
import { NotificationProvider } from "~contexts/NotificationProvider.tsx";
import { RemSizingProvider } from "~hooks/useRemSizing.tsx";
import {
	BrandProvider,
	Compose,
	ContentSizeProvider,
	ToastProvider,
	ZipPortal,
} from "~zd-ui";
import { ErrorScrollingProvider } from "~zipdeal-ui/inputs/common/ErrorMessage.tsx";
import { SearchParamsKeeperProvider } from "~zipdeal-ui/query-filters/SearchParamsKeeperProvider.tsx";
import { SearchParamsManager } from "~zipdeal-ui/query-filters/SearchParamsManager.tsx";
import "./styles/critical.css";
import "./styles/other.css";
import "./styles/tailwind.css";

setAutoFreeze(false);
enableMapSet();
if (!Object.hasOwn) {
	Object.hasOwn = (obj, prop) =>
		Object.prototype.hasOwnProperty.call(obj, prop);
}
export { loader } from "./loader.root.server.ts";

export const meta = () => [
	{
		title: "ZipDeal",
	},
];

// const session = createSessionMiddleware(
// 	createCookieSessionStorage<SessionData, SessionFlashData>({
// 		cookie: {
// 			name: "__session",
// 			path: "/",
// 			sameSite: "lax",
// 			secrets: ["s3cret1"],
// 		},
// 	}),
// );

// export your middleware as array of functions that Remix will call
// wrap middleware in serverOnly$ to prevent it from being bundled in the browser
// since remix doesn't know about middleware yet
export const middleware = serverOnly$([
	// session,
	requestInfoMiddleware,
	apolloClientMiddleware,
	authenticatedUserMiddleware,
	zipDealVersionMiddleware,
	dealershipMiddleware,
]);

export function Layout({ children }: { children: ReactNode }) {
	return (
		<html lang={"en-US"}>
			<head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				{/*<link rel="apple-touch-icon" href="/apple-touch-icon.png" />*/}
				<Meta />
				<Links />
			</head>
			<body suppressHydrationWarning>
				{children}
				<ScrollRestoration />
				<Scripts />
			</body>
		</html>
	);
}
function App() {
	const portalsRef = React.useRef<HTMLDivElement>(null);
	const modals = useSelector(DumbModalSystemRef, (state) =>
		Object.values(state.context.modals),
	);

	return (
		<>
			<Compose
				providers={[
					SearchParamsManager,
					SearchParamsKeeperProvider,
					ErrorScrollingProvider,
					ToastProvider,
					NotificationProvider,
					ProvideAuth,
					RemSizingProvider,
					[BrandProvider, { defaultValue: "base" }],
					[ContentSizeProvider, { portalsRef }],
				]}
			>
				{/* children will be the root Component, ErrorBoundary, or HydrateFallback */}
				<Outlet />
			</Compose>
			<div
				ref={portalsRef}
				id={"portals-area"}
				className={"z-9999 relative"}
			></div>
			{modals.length > 0 && (
				<ZipPortal container={portalsRef?.current}>
					{modals.map((modal) => {
						const snapshot = modal.getSnapshot();
						return snapshot.context.component({
							dismissFn: () => modal.send({ type: "dismiss" }),
						});
					})}
				</ZipPortal>
			)}
		</>
	);
}

let theApp = App;
if (process.env.NODE_ENV !== "development") {
	theApp = withSentry(App);
} else {
	theApp = App;
	// theApp = withDevTools(App);
}

export default theApp;

export const ErrorBoundary = () => {
	const error = useRouteError();

	console.log(error);
	if (isRouteErrorResponse(error)) {
		return (
			<>
				<h1>
					{error.status} {error.statusText}
				</h1>
				<p>{error.data}</p>
			</>
		);
	}

	return (
		<>
			<div
				data-error={"error-3"}
				className={cn(
					"grid h-full min-h-dvh place-content-center bg-red-100 text-2xl text-gray-400",
				)}
			>
				Oh no!! We seem to be having an issue here. Please try again.
			</div>
		</>
	);
};
